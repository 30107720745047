<template>
    <div>
        <markdown
            :value="pack.description"
            v-on:save-content="changeAttribute"
            :label="$t('packs.actions.add_description')"
            :allow-editing="this.allowEditing"
            v-if="!onlyEdit"
        />
        <editor v-model="editingValue" v-if="onlyEdit" />
    </div>
</template>
<script>
import { mapGetters, mapActions } from 'vuex';
import markdown from '@/components/shared/markdown';
import editor from '@/components/shared/editor';

export default {
    name: 'pack-description',
    data: () => ({
        editing: false,
        editingValue: undefined,
    }),
    props: {
        pack: {},
        allowEditing: {
            default: true,
        },
        onlyEdit: {
            default: false,
        },
    },
    components: {
        markdown,
        editor,
    },
    watch: {
        editingValue: function (e) {
            if (this.onlyEdit) {
                this.$emit('input', e);
            }
        },
    },
    computed: {
        ...mapGetters(['profile']),
    },
    methods: {
        ...mapActions(['updatePack']),
        async changeAttribute(value) {
            await this.updatePack({
                id: this.pack.id,
                description: value,
            });

            this.editing = false;
            this.$emit('change-attribute-value');
        },
    },
};
</script>
