<template>
    <v-autocomplete
        v-model="localVersions"
        :items="versions"
        :label="label"
        chips
        deletable-chips
        v-on:input="selectVersion"
    ></v-autocomplete>
</template>
<script>
import { mapGetters, mapActions } from 'vuex';

export default {
    name: 'selector-version',
    data: () => ({
        localVersions: [],
    }),
    created() {
        if (this.projectFilter) {
            this.findProject(this.projectFilter);
        }
    },
    mounted() {
        this.localVersions = this.value;
    },
    props: {
        value: {},
        label: {},
        versionStatus: {
            default: undefined,
        },
        excludes: {
            default: () => [],
        },
        projectFilter: {
            default: undefined,
        },
        displayStatus: {
            default: false,
        },
        displayState: {
            default: false,
        },
        inDevelop: {
            default: undefined,
        },
    },
    watch: {
        localVersions: function (value) {
            this.$emit('input', value);
        },
        value: function (value) {
            this.localVersions = value;
        },
        projectFilter: function (value) {
            this.findProject(value);
        },
    },
    computed: {
        ...mapGetters(['project']),
        versions() {
            if (this.project) {
                const versions = this.project.versions
                    .filter((v) => !this.excludes.includes(v.name))
                    .filter((v) => {
                        let validate = true;
                        if (this.versionStatus) {
                            validate = validate && v.status === this.versionStatus;
                        }
                        if (this.inDevelop) {
                            validate = validate && v.development === this.inDevelop;
                        }
                        return validate;
                    });
                return versions
                    .map((v) => {
                        let label = v.name;
                        if (this.displayStatus) {
                            label = label + ` (${this.$t('lists.versionStatus.' + v.status)})`;
                        }
                        if (this.displayState && v.development) {
                            label = label + ` - ${this.$t('lists.versionState.' + v.development)}`;
                        }
                        return {
                            text: label,
                            value: v.name,
                        };
                    })
                    .sort((a, b) => {
                        const aName = a.value.replace(/\d+/g, (n) => +n + 100000);
                        const bName = b.value.replace(/\d+/g, (n) => +n + 100000);
                        return aName.localeCompare(bName);
                    })
                    .reverse();
            }
            return [];
        },
    },
    methods: {
        ...mapActions(['findProject']),
        selectVersion(item) {
            this.$emit(
                'update-version',
                this.project.versions.find((v) => {
                    return v.name === item;
                }),
            );
        },
    },
};
</script>
