<template>
    <v-card flat>
        <v-divider />
        <v-dialog v-model="dialog" width="500">
            <create-project
                v-if="type === 'create'"
                :pack="pack"
                :version="version"
                v-on:cancel-create="cancelDialog()"
            />
            <edit-project
                v-if="type === 'update'"
                :pack="pack"
                :version="version"
                :project="project"
                v-on:cancel-create="cancelDialog()"
            />
        </v-dialog>
        <v-container>
            <v-row>
                <v-col>
                    <version-name
                        :pack="pack"
                        :version="version"
                        :allow-editing="profile.security.packsCreate"
                        class="title"
                    />
                </v-col>

                <v-col cols="2">
                    <v-chip label small :color="statusColor(version.status)" dark>
                        <v-icon left> {{ statusIcon(version.status) }} </v-icon>
                        {{ $t('lists.packStatus.' + version.status) }}
                    </v-chip>
                </v-col>
            </v-row>
        </v-container>
        <v-card-text>
            <v-chip-group column>
                <v-chip
                    v-for="(p, j) in version.projects"
                    :key="'pj' + j"
                    class="ma-2"
                    :close="profile.security.packsCreate"
                    :color="classProject"
                    label
                    outlined
                    @click="profile.security.packsCreate ? openProject(p) : undefined"
                    @click:close="remove(p)"
                >
                    {{ projectLabel(p.project) }}
                    <template v-if="p.scope"> ({{ p.scope }}) </template>
                    - Version {{ p.version }}
                </v-chip>
            </v-chip-group>
        </v-card-text>

        <v-card-actions>
            <v-tooltip top dark color="grey darken-4">
                <template v-slot:activator="{ on }">
                    <v-btn
                        v-if="version.status === 'finish'"
                        color="blue"
                        text
                        small
                        @click="showFile(version.file)"
                        v-on="on"
                    >
                        <v-icon left> mdi-archive-arrow-down-outline </v-icon>
                        {{ $t('buttons.versionPackDownload') }}
                    </v-btn>
                </template>
                <span v-if="version.status === 'finish'">
                    {{ $t('packs.versions.attributes.size') }}: {{ labelSize(version.file.size) }} -
                    {{ $t('packs.versions.attributes.createdAt') }}:
                    {{ formatDate(version.file.createdAt, 'dd-MMM HH:mm') }}
                </span>
            </v-tooltip>
            <v-spacer />
            <v-btn v-if="profile.security.packsCreate" color="indigo" dark text small @click="createProject()">
                <v-icon left> mdi-plus </v-icon>
                {{ $t('packs.versions.actions.addProject') }}
            </v-btn>
            <v-btn
                v-if="
                    profile.security.packsCreate &&
                    version.projects.length > 0 &&
                    (version.status === 'empty' || pack.nightly)
                "
                color="success"
                dark
                text
                small
                @click="generate()"
            >
                <v-icon left> mdi-update </v-icon>
                {{ $t('packs.versions.actions.generate') }}
            </v-btn>
            <v-btn v-if="profile.security.packsCreate" color="red" dark text small @click="deleteVersion()">
                <v-icon left> mdi-delete </v-icon>
                {{ $t('packs.versions.actions.delete') }}
            </v-btn>
        </v-card-actions>
    </v-card>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import versionName from '@/components/packs/versionName';
import createProject from './createProject';
import editProject from './editProject';
import { formatDateMixin } from '@/mixins/dates';

export default {
    name: 'pack-versions',
    data: () => ({
        dialog: false,
        type: undefined,
        project: undefined,
    }),
    created() {},
    mixins: [formatDateMixin],
    components: {
        versionName,
        createProject,
        editProject,
    },
    watch: {},
    props: {
        pack: {
            required: true,
        },
        version: {
            required: true,
        },
    },
    computed: {
        ...mapGetters(['profile', 'token', 'projects']),
        classProject() {
            return this.$vuetify.theme.dark ? 'blue-grey lighten-4' : 'blue-grey';
        },
        headers() {
            return [
                {
                    text: '',
                    sortable: true,
                    value: 'name',
                },
            ];
        },
    },
    methods: {
        ...mapActions(['generatePackVersion', 'removeProjectToPackVersion', 'deleteVersionToPack']),
        projectLabel(project) {
            if (this.projects) {
                return this.projects.find((p) => p.name === project).label;
            }
            return '';
        },
        statusColor(status) {
            if (status === 'running') {
                return 'amber darken-2';
            }
            if (status === 'finish') {
                return 'light-green darken-2';
            }
            return 'red darken-2';
        },
        statusIcon(status) {
            if (status === 'running') {
                return 'mdi-refresh';
            }
            if (status === 'finish') {
                return 'mdi-check';
            }
            return 'mdi-close';
        },
        generate() {
            const packVersion = {
                id: this.pack.id,
                version: this.version.id,
            };
            this.generatePackVersion(packVersion);
        },
        remove(item) {
            const project = {
                pack_id: this.pack.id,
                version_id: this.version.id,
                id: item.id,
            };

            this.removeProjectToPackVersion(project);
        },
        showFile(file) {
            const a = document.createElement('a');
            a.style.display = 'none';
            document.body.appendChild(a);
            a.href = `${file.url}?token=${this.token}`;
            a.setAttribute('download', file.filename);
            a.click();
            document.body.removeChild(a);
        },
        labelSize(bytes) {
            const sizes = ['Bits', 'Ko', 'Mo', 'Go', 'To'];
            if (bytes == 0) return '0 Bit';
            const i = parseInt(Math.floor(Math.log(bytes) / Math.log(1024)));
            return Math.round(bytes / Math.pow(1024, i), 2) + ' ' + sizes[i];
        },
        deleteVersion() {
            const packVersion = {
                pack_id: this.pack.id,
                id: this.version.id,
            };
            this.deleteVersionToPack(packVersion);
        },
        createProject() {
            this.dialog = true;
            this.type = 'create';
        },
        openProject(p) {
            this.project = p;
            this.dialog = true;
            this.type = 'update';
        },
        cancelDialog() {
            this.dialog = false;
            this.type = undefined;
        },
    },
};
</script>

<style scoped>
.table-selector {
    cursor: pointer;
}
</style>
