<template>
    <v-card>
        <v-card-title>
            <span class="headline blue--text text--darken-1">
                {{ $t('packs.versions.actions.edit') }}
            </span>
        </v-card-title>
        <v-card-subtitle class="headline blue--text text--darken-1">
            {{ projectLabel(project.project) }}
            <template v-if="project.scope"> ({{ project.scope }}) </template>
        </v-card-subtitle>

        <v-card-text>
            <version-selector
                :label="$t('packs.versions.projects.version')"
                v-model="projectVersion"
                :projectFilter="filterProject"
                :display-status="true"
                :display-state="true"
            />
        </v-card-text>

        <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="red darken-1" text @click="cancel">
                {{ $t('buttons.cancel') }}
            </v-btn>
            <v-btn color="blue darken-1" text @click="update">
                {{ $t('buttons.save') }}
            </v-btn>
        </v-card-actions>
    </v-card>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import versionSelector from '@/components/selectors/simple/version';

export default {
    name: 'pack-edit-project',
    data: () => ({
        projectVersion: undefined,
    }),
    mounted() {
        this.projectVersion = this.project.version;
    },
    components: { versionSelector },
    watch: {},
    props: {
        pack: {
            required: true,
        },
        version: {
            required: true,
        },
        project: {
            required: true,
        },
    },
    computed: {
        ...mapGetters(['profile', 'projects']),
        filterProject() {
            return this.projects.find((p) => p.name === this.project.project).id;
        },
    },
    methods: {
        ...mapActions(['updateProjectToPackVersion']),
        projectLabel(project) {
            if (this.projects) {
                return this.projects.find((p) => p.name === project).label;
            }
            return '';
        },
        init() {
            this.projectVersion = undefined;
        },
        update() {
            this.updateProjectToPackVersion({
                pack_id: this.pack.id,
                version_id: this.version.id,
                id: this.project.id,
                version: this.projectVersion,
            });
            this.cancel();
        },
        cancel() {
            this.$emit('cancel-create');
            this.init();
        },
    },
};
</script>
