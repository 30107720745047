<template>
    <v-select v-model="localVersion" :items="versions" v-bind="$props"></v-select>
</template>
<script>
import { mapGetters, mapActions } from 'vuex';

export default {
    name: 'selector-version',
    data: () => ({
        localVersion: undefined,
    }),
    created() {},
    mounted() {
        this.localVersion = this.value;
    },
    props: {
        value: {},
        label: {},
    },
    watch: {
        localVersion: function (value) {
            this.$emit('input', value);
        },
        value: function (value) {
            this.localVersion = value;
        },
    },
    computed: {
        ...mapGetters(['pack']),
        versions() {
            if (this.pack) {
                return this.pack.versions
                    .map((v) => {
                        return {
                            text: v.name,
                            value: v.id,
                        };
                    })
                    .sort((a, b) => {
                        const aName = a.text.replace(/\d+/g, (n) => +n + 100000);
                        const bName = b.text.replace(/\d+/g, (n) => +n + 100000);
                        return aName.localeCompare(bName);
                    })
                    .reverse();
            }
            return [];
        },
    },
};
</script>
