<template>
    <v-select v-model="localScope" :items="scopes" v-bind="$props"></v-select>
</template>
<script>
import { mapActions } from 'vuex';
import { API } from '@/utils/api';

export default {
    name: 'selector-version-scope',
    data: () => ({
        localScope: undefined,
        files: [],
    }),
    created() {},
    async mounted() {
        this.localScope = this.value;
        const gettingFiles = await API.get(`projects/${this.project}/versions/${this.version}/files`);
        this.files = gettingFiles.data.datas;
    },
    props: {
        label: {},
        project: {
            required: true,
        },
        version: {
            required: true,
        },
    },
    watch: {
        localScope: function (value) {
            this.$emit('input', value);
        },
        value: function (value) {
            this.localScope = value;
        },
    },
    computed: {
        scopes() {
            return [...new Set(this.files.map((f) => f.category))];
        },
    },
    methods: {
        ...mapActions(['findProject']),
    },
};
</script>
