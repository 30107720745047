<template>
    <v-container fluid v-if="pack">
        <v-row>
            <v-col cols="8" offset-md="2">
                <v-card>
                    <v-container>
                        <v-row>
                            <v-col>
                                <pack-label
                                    class="mx-2 title"
                                    :pack="pack"
                                    :allow-editing="profile.security.packsCreate"
                                />
                            </v-col>
                            <v-col cols="2">
                                <v-dialog v-model="dialogCreate" max-width="500px" persistent>
                                    <template v-slot:activator="{ on }">
                                        <v-btn color="primary" small dark v-on="on" v-if="profile.security.packsCreate">
                                            {{ $t('packs.actions.add_version') }}
                                        </v-btn>
                                    </template>
                                    <v-card>
                                        <v-card-title>
                                            <span class="headline blue--text text--darken-1">{{
                                                $t('title.newPackVersion')
                                            }}</span>
                                        </v-card-title>

                                        <v-card-text>
                                            <pack-version-selector
                                                v-model="versionModel"
                                                :label="$t('packs.versions.attributes.model')"
                                            />
                                            <v-text-field
                                                v-model="creatingVersion"
                                                :counter="50"
                                                :label="$t('packs.versions.attributes.name')"
                                                required
                                                color="red"
                                            ></v-text-field>
                                        </v-card-text>

                                        <v-card-actions>
                                            <v-spacer></v-spacer>
                                            <v-btn color="red darken-1" text @click="cancelCreate">
                                                {{ $t('buttons.cancel') }}
                                            </v-btn>
                                            <v-btn color="blue darken-1" text @click="create">
                                                {{ $t('buttons.save') }}
                                            </v-btn>
                                        </v-card-actions>
                                    </v-card>
                                </v-dialog>
                            </v-col>
                            <v-col cols="1">
                                <v-btn text small @click="back()">
                                    {{ $t('buttons.back') }}
                                </v-btn>
                            </v-col>
                        </v-row>
                    </v-container>

                    <v-card-subtitle>
                        <pack-description :pack="pack" :allow-editing="profile.security.packsCreate" />
                    </v-card-subtitle>

                    <v-card-text>
                        <pack-version :pack="pack" :version="version" v-for="(version, i) in versions" :key="i" />
                    </v-card-text>
                </v-card>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import packVersion from './pack/version';
import packLabel from '@/components/packs/label';
import packDescription from '@/components/packs/description';
import packVersionSelector from '@/components/selectors/simple/packVersion';

export default {
    name: 'pack',
    data: () => ({
        dialogCreate: false,
        versionModel: undefined,
        creatingVersion: undefined,
    }),
    created() {
        this.fetchPack();
    },
    components: {
        packVersion,
        packLabel,
        packDescription,
        packVersionSelector,
    },
    computed: {
        ...mapGetters(['pack', 'profile']),
        versions() {
            const versions = [...this.pack.versions];
            return versions
                .sort((a, b) => {
                    const aName = a.name.replace(/\d+/g, (n) => +n + 100000);
                    const bName = b.name.replace(/\d+/g, (n) => +n + 100000);
                    return aName.localeCompare(bName);
                })
                .reverse();
        },
    },
    methods: {
        ...mapActions(['findPack', 'addVersionToPack']),
        back() {
            this.$router.push({
                name: 'packs',
            });
        },
        fetchPack() {
            const idToGet = this.$route.params.pack_id;
            this.findPack(idToGet);
        },
        cancelCreate() {
            this.dialogCreate = false;
            this.creatingVersion = undefined;
            this.versionModel = undefined;
        },
        create() {
            this.addVersionToPack({
                pack_id: this.pack.id,
                name: this.creatingVersion,
                model: this.versionModel,
            });
            this.cancelCreate();
        },
    },
};
</script>

<style></style>
