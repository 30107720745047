<template>
    <v-card>
        <v-card-title>
            <span class="headline blue--text text--darken-1">{{ $t('title.newProject') }}</span>
        </v-card-title>

        <v-card-text>
            <project-selector
                :label="$t('packs.versions.projects.name')"
                v-model="creatingProject.project"
                type="name"
                v-on:update-project="addFilterProject"
            />

            <version-selector
                :label="$t('packs.versions.projects.version')"
                v-if="creatingProject.project"
                v-model="creatingProject.version"
                :projectFilter="filterProject.id"
                v-on:update-version="addFilterVersion"
                :display-status="true"
                :display-state="true"
            />

            <version-scope-selector
                :label="$t('packs.versions.projects.scope')"
                v-if="creatingProject.version"
                v-model="creatingProject.scope"
                :project="filterProject.id"
                :version="filterVersion.id"
            />
        </v-card-text>

        <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="red darken-1" text @click="cancel">
                {{ $t('buttons.cancel') }}
            </v-btn>
            <v-btn color="blue darken-1" text @click="create">
                {{ $t('buttons.save') }}
            </v-btn>
        </v-card-actions>
    </v-card>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import projectSelector from '@/components/selectors/simple/project';
import versionSelector from '@/components/selectors/simple/version';
import versionScopeSelector from '@/components/selectors/simple/versionScope';

export default {
    name: 'pack-create-project',
    data: () => ({
        creatingProject: {
            project: undefined,
            version: undefined,
            scope: undefined,
        },
        filterProject: undefined,
        filterVersion: undefined,
    }),
    mounted() {
        this.init();
    },
    components: { projectSelector, versionSelector, versionScopeSelector },
    watch: {},
    props: {
        pack: {
            required: true,
        },
        version: {
            required: true,
        },
    },
    computed: {
        ...mapGetters(['profile']),
    },
    methods: {
        ...mapActions(['addProjectToPackVersion']),
        init() {
            this.creatingProject = { project: undefined, version: undefined, scope: undefined };
        },
        addFilterProject(p) {
            this.filterProject = p;
        },
        addFilterVersion(v) {
            this.filterVersion = v;
        },
        create() {
            const project = Object.assign(
                {
                    pack_id: this.pack.id,
                    version_id: this.version.id,
                },
                this.creatingProject,
            );
            this.addProjectToPackVersion(project);
            this.cancel();
        },
        cancel() {
            this.$emit('cancel-create');
            this.init();
        },
    },
};
</script>
